import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 

import { getApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions"

import ObjExpertiseSectionSite from '../ObjExpertise/ObjExpertiseSectionSite'
import ObjExpertiseSectionBrand from '../ObjExpertise/ObjExpertiseSectionBrand'
import ObjExpertiseSectionSiteBefore from '../ObjExpertise/ObjExpertiseSectionSiteBefore'
import ObjExpertiseBadges from '../ObjExpertise/ObjExpertiseBadges'

class PageMonCompteExpertise extends Component {
	constructor(props) {
		super(props)
		this.state = {
			apiDatas:[],
		}
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Call/PageExpertise`).then((apiDatas) => { this.setState({ apiDatas:apiDatas }) })

		// add body class
		document.body.classList.add('pg_compte')
		window.scrollTo(0,0)
		// add and dispatch window resize
		window.addEventListener('resize', this.throttleWindowResizeSectionCompte)
		window.dispatchEvent(new Event('resize'))
  	}
  	componentWillUnmount() {
		// remove body class
		document.body.classList.remove('pg_compte')
		// remove window resize
		window.removeEventListener('resize', this.throttleWindowResizeSectionCompte)
	}
	render() {
		const apiDatas = this.state.apiDatas

		return (<>
			<section className="obj_section section_expertise">
				<div className="ctn_section">
					<div className="ctn_title">
						<h2 className="t_title">Mon expertise</h2>
					</div>
					{apiDatas.UserSiteExpertise &&
						<div className="obj_accordion">
							<ObjExpertiseSectionSite 
								user={apiDatas.UserSiteExpertise}
								ranking={apiDatas.RankingSite}
								badges={apiDatas.Badges.filter(o => o.Badge_Brand_Id==null)}
								userBadges={apiDatas.UserBadges}
							/>
							{apiDatas.UserBrandExpertise.map( (userBrand,index) => 
								<ObjExpertiseSectionBrand 
									key={index}
									user={userBrand}
									ranking={apiDatas.RankingBrand.find(o => o.Brand_Id===userBrand.Brand_Id).RankingExpertise}
									badges={apiDatas.Badges.filter(o => o.Badge_Brand_Id===userBrand.Brand_Id)}
									userBadges={apiDatas.UserBadges}
								/>
							)}
							<ObjExpertiseSectionSiteBefore
                                label="2024"
								user={apiDatas.UserSiteExpertise2024}
								ranking={apiDatas.RankingSite2024}
								badges={apiDatas.Badges}
								userBadges={apiDatas.UserBadges2024}
								UserBrandExpertise={apiDatas.UserBrandExpertise2024}
							/>
						</div>
					}
				</div>
			</section>

			<section className="obj_section obj_section section_tableau_recompenses">
				<div className="ctn_section">
					<div className="ctn_title">
						<h2 className="t_subtitle"><strong>Tableau des <br />Récompenses</strong></h2>
					</div>
					{apiDatas.BadgesCategories &&
						<div className="obj_tableau_recompences_exp">
							<ObjExpertiseBadges 
								categories={apiDatas.BadgesCategories} 
								badges={apiDatas.Badges.filter(o => o.Badge_Brand_Id==null || apiDatas.UserBrandExpertise.find(brand => brand.Brand_Id === o.Badge_Brand_Id))}
								userBadges={apiDatas.UserBadges}/>
						</div>
					}
				</div>
			</section>
		</>)
	}
}

export default withTranslation()(PageMonCompteExpertise)