export const setStyleColors = () => {
	const brands = [
		{name: 'lrp', main: '#009fe3', col_rgba: '0,159,227'},
		{name: 'vichy', main: '#C8102E', col_rgba: '200,16,46'},
		{name: 'sanoflore', main: '#1f3c1d', col_rgba: '31,60,29'},
		{name: 'cerave', main: '#2570B8', col_rgba: '37,112,184'},
		{name: 'skinc', main: '#000000', col_rgba: '0,0,0'},
		{name: 'biotherm', main: '#206170', col_rgba: '32,97,112'},
		{name: 'sciencemd', main: '#000', col_rgba: '0,159,227'},
		{name: 'derm-expertise', main: '#02ACC2', col_rgba: '2,172,194'},
		{name: 'comptoir-dermato', main: '#81C9D3', col_rgba: '129,201,211'},
	];

	brands.forEach(brand => {
		const styleColors = document.createElement('style');
		const name = brand.name;
		const main = brand.main;
		const alt = brand.alt;
		const col_rgba = brand.col_rgba;

		const styleColorsContent = [
			// .obj_cta
			".var_"+ name +" .obj_cta{",
			"	background:"+ main +";",
			"	}",
			".var_"+ name +" .obj_cta.reverse{",
			"	color:"+ main +";",
			"	background:#fff;",
			"	}",
			".var_"+ name +" .obj_cta-download{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .obj_cta-download svg{",
			"	fill:"+ main +";",
			"	}",
			// .main_header
			".var_"+ name +" .main_header .ctn_nav-marque li.var_marque a{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .main_header .ctn_nav-marque ul.lvl_01 > li > a::before{",
			"	background:"+ main +";",
			"	}",
			// .obj_push-article
			".obj_push-article.var_"+ name +" h3{",
			"	color:"+ main +";",
			"	}",
			".obj_push-article.var_"+ name +" h3::before{",
			"	background:"+ main +";",
			"	}",
			// .obj_back-to-top
			".var_"+ name +" .obj_back-to-top{",
			"	background:"+ main +";",
			"	}",
			// .section_social-network
			".var_"+ name +" .section_social-network ul li a svg{",
			"	fill:"+ main +";",
			"	}",
			// .section_wide-carousel
			".section_wide-carousel .obj_carousel .ctn_banner.var_"+ name +" .ctn_text h2{",
			"	color:"+ main +";",
			"	}",
			// .obj_push-custom
			".var_"+ name +" .obj_push-custom.var_full .ctn_text h2{",
			"	color:"+ main +";",
			"	}",
			// .section_article
			".var_"+ name +" .section_article .ctn_article h3{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_article .ctn_text .ctn_like-article .bt_like-article svg{",
			"	fill:"+ main +";",
			"	}",
			// .section_article
			".var_"+ name +" .obj_back-page .bt_back-page{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .obj_back-page .bt_back-page::before{",
			"	background:"+ main +";",
			"	}",
			".var_"+ name +" .obj_back-page .bt_back-page::after{",
			"	border-color:"+ main +";",
			"	}",
			".var_"+ name +" .section_article .ctn_comments .obj_form .ctn_single-file-upload .obj_cta{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus{",
			"	border-color:"+ main +";",
			"	}",
			".var_"+ name +" .section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::before,",
			".var_"+ name +" .section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::after{",
			"	background:"+ main +";",
			"	}",
			".var_"+ name +" .section_article .ctn_comments .bt_reply{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_article .ctn_comments .bt_reply::before,",
			".var_"+ name +" .section_article .ctn_comments .bt_reply::after{",
			"	border-color:"+ main +";",
			"	}",
			".var_"+ name +" .section_article .ctn_comments .bt_more{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_article .ctn_article .ctn_text p a{",
			"	color:"+ main +";",
			"	}",
			// .obj_form
			".var_"+ name +" .obj_form .t_mention a,",
			".var_"+ name +" .obj_form .ctn_radio label a,",
			".var_"+ name +" .obj_form .ctn_checkbox label a{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .obj_form .ctn_single-file-upload .bt_cancel{",
			"	background:"+ main +";",
			"	}",
			// .obj_tabs-list
			".var_"+ name +" .obj_tabs-list .ctn_nav-tabs li.active button{",
			"	border-bottom-color:"+ main +";",
			"	}",
			// .obj_push-product
			".var_"+ name +" .obj_push-product .t_desc{",
			"	color:"+ (alt ? alt : main) +";",
			"	}",
			".var_"+ name +" .obj_push-product .t_title-routine{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .obj_push-product .t_desc-routine svg{",
			"	fill:"+ main +";",
			"	}",
			".var_"+ name +" .obj_push-product .fake_cta{",
			"	background:"+ main +";",
			"	}",
			// .section_product-detail
			".var_"+ name +" .section_product-detail .ctn_top-detail h1 em{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_product-detail .ctn_top-detail h2{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_product-detail .ctn_top-detail .col_image figure figcaption svg{",
			"	fill:"+ main +";",
			"	}",
			".var_"+ name +" .section_product-detail .ctn_desc-product.var_plus{",
			"	background:"+ main +";",
			"	}",
			".var_"+ name +" .section_product-detail .ctn_desc-product ul li::before{",
			"	background:"+ main +";",
			"	}",
			".var_"+ name +" .section_product-detail .ctn_desc-product h3{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_product-detail .ctn_desc-product p em{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_product-detail .ctn_top-detail .col_image figure figcaption{",
			"	color:"+ main +";",
			"	}",
			// .section_product-carousel
			".var_"+ name +" .section_product-carousel h2{",
			"	color:"+ main +";",
			"	}",
			// .section_liste-ingredients
			".var_"+ name +" .section_liste-ingredients .obj_liste-ingredients .ctn_text h2{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_liste-ingredients .obj_nav-ingredients li button::before,",
			".var_"+ name +" .section_liste-ingredients .obj_nav-ingredients li a::before{",
			"	background:"+ main +";",
			"	}",
			// .section_detail-ingredients
			".var_"+ name +" .section_detail-ingredients h1,",
			".var_"+ name +" .section_detail-ingredients h2{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .section_detail-ingredients .ctn_bienfaits-ingredients .col_right{",
			"	background:"+ main +";",
			"	}",
			// .section_cercle-elite_header
			".pg_concours_home.var_"+ name +" .obj_breadcrumb::before,",
			".pg_concours_home.var_"+ name +" .obj_title-big::before,",
			".pg_concours_home.var_"+ name +" .obj_push-article::before{",
			"	background:"+ main +";",
			"	}",
			// .obj_likes-comments
			".var_"+ name +" .obj_likes-comments .ctn_like-article .bt_like-article.is-checked{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .obj_likes-comments .ctn_like-article .bt_like-article.is-checked svg{",
			"	fill:"+ main +";",
			"	}",
			// .section_formation-dashboard
			".var_"+ name +" .obj_progress-bar-expertise .obj_progress-bar{",
			"	background:rgba("+ col_rgba +",0.15);",
			"	}",
			// .progress-bar-expertise
			".var_"+ name +" .obj_progress-bar-expertise .obj_progress-bar .step.is-complete{",
			"	background:"+ main +";",
			"	}",
			// ..obj_cta-formation
			".var_"+ name +" .ctn_page-liste-visuel .obj_cta-formation{",
			"	background-color:rgba("+ col_rgba +",0.7);",
			"	}",
			".var_"+ name +" .is-done .ctn_page-liste-visuel .obj_cta-formation,",
			".var_"+ name +" .is-not-available .ctn_page-liste-visuel .obj_cta-formation{",
			"	background-color:rgba(0,0,0,0.2)",
			"	}",
			// .obj_bloc_expertise
			".var_"+ name +".obj_bloc_expertise{",
			"	background-color:rgba("+ col_rgba +",0.15);",
			"	}",
			".var_"+ name +".obj_bloc_expertise .t_title{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .obj_item_expertise .ctn_text .t_tag span,",
			".var_"+ name +".obj_item_expertise .ctn_text .t_tag span{",
			"	background:rgba("+ col_rgba +",0.15);",
			"	}",
			".var_"+ name +".obj_bloc_expertise .obj_header_exp:before{",
			"	border-top:solid 2px "+ main +";",
			"	border-right:solid 2px "+ main +";",
			"	}",
			".var_"+ name +".obj_bloc_expertise .obj_header_exp .t_tag span{",
			"	background-color:rgba("+ col_rgba +",0.15);",
			"	}",
			".var_"+ name +".obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp.active{",
			"	border-color:rgba("+ col_rgba +",1);",
			"	background-color:rgba("+ col_rgba +",1);",
			"	}",
			// .section_formation-dashboard
			".section_formation-dashboard .var_"+ name +".obj_bloc_formation{",
			"	background-color:rgba("+ col_rgba +",0.15);",
			"	}",
			".section_formation-dashboard .var_"+ name +".obj_bloc_formation .t_title{",
			"	color:"+ main +";",
			"	}",
			".section_formation-dashboard .var_"+ name +".obj_bloc_formation .obj_header_formation:before{",
			"	border-top:solid 2px "+ main +";",
			"	border-right:solid 2px "+ main +";",
			"	}",
			".section_formation-dashboard .var_"+ name +".obj_bloc_formation .obj_header_formation .t_tag span{",
			"	background-color:rgba("+ col_rgba +",0.15);",
			"	}",
			// .obj_cta-formation
			".var_"+ name +" .obj_formation_mini_parcours .obj_cta-formation{",
			"	background-color:rgba("+ col_rgba +",0.7);",
			"	}",
			".var_"+ name +" .is-done.obj_formation_mini_parcours .obj_cta-formation,",
			".var_"+ name +" .is-not-available.obj_formation_mini_parcours .obj_cta-formation{",
			"	background-color:rgba(0,0,0,0.2)",
			"	}",
			// .section_mini_parcours
			".var_"+ name +" .section_mini_parcours .ctn_title .t_subtitle{",
			"	color:"+ main +";",
			"	}",
			".var_"+ name +" .obj_carousel.obj_carrousel_mini_parcours .slick-prev:not(.slick-disabled)::before{",
			"	border-bottom: solid 2px rgba("+ col_rgba +",1);",
			"	border-left: solid 2px rgba("+ col_rgba +",1);",
			"	}",
			".var_"+ name +" .obj_carousel.obj_carrousel_mini_parcours .slick-next:not(.slick-disabled)::before{",
			"	border-top: solid 2px rgba("+ col_rgba +",1);",
			"	border-right: solid 2px rgba("+ col_rgba +",1);",
			"	}",
			// .obj_cta
			".var_"+ name +" .obj_cta:hover,",
			".var_"+ name +" .obj_push-custom.var_full a:hover .obj_cta{",
			"	color:"+ main +";",
			"	background:#fff;",
			"	}",
			".var_"+ name +" .obj_cta.reverse:hover,",
			".var_"+ name +" .obj_push-custom.var_full a:hover .obj_cta.reverse{",
			"	color:#fff;",
			"	background:"+ main +";",
			"	}",
			".var_"+ name +" .obj_cta.var_email:hover svg{",
			"	fill:"+ main +";",
			"	}",
			// .section_social-network
			".var_"+ name +" .section_social-network ul li a:hover svg{",
			"	fill:#777;",
			"	}",
			// .section_article
			".var_"+ name +" .section_article .ctn_text .ctn_like-article .bt_like-article:hover{",
			"	color:"+ main +";",
			"	}",
			// .obj_tabs-list
			".var_"+ name +" .obj_tabs-list .ctn_nav-tabs li:not(.active) button:hover{",
			"	color:"+ main +";",
			"	}",
			// .section_product-detail
			".var_"+ name +" .section_product-detail .ctn_top-detail .col_image ul li:hover span{",
			"	color:"+ main +";",
			"	}",
			// .obj_likes-comments
			".var_"+ name +" .obj_likes-comments .ctn_like-article .bt_like-article:hover{",
			"	color:"+ main +";",
			"	}",
			// .obj_progress-bar-expertise .obj_cta
			".var_"+ name +".obj_progress-bar-expertise:hover .obj_cta,",
			".var_"+ name +".obj_progress-bar-expertise.hover .obj_cta{",
			"	color:#fff;",
			"	background:"+ main +";",
			"	}",
			// .section_page-liste
			".var_"+ name +" .section_page-liste .ctn_text .t_tag span,",
			".var_"+ name +" .section_page-liste .section_list_formation .ctn_text .t_tag span{",
			"	background:"+ main +";",
			"	}",
			".var_"+ name +" .section_page-liste .ctn_text .bt_fav-formation.is-checked svg,",
			".var_"+ name +" .section_page-liste .section_list_formation .ctn_text .bt_fav-formation.is-checked svg,",
			".section_list_formation .var_"+ name +".ctn_brand .ctn_text .bt_fav-formation.is-checked svg{",
			"	fill:"+ main +";",
			"	}",
			".var_"+ name +" .section_page-liste .obj_formation_mini_parcours .ctn_text .bt_fav-formation:hover:not(:disabled,.is-checked) svg,",
			".var_"+ name +" .section_page-liste .section_list_formation .ctn_text .bt_fav-formation:hover:not(:disabled,.is-checked) svg{",
			"	fill:rgba("+ col_rgba +",0.5);",
			"	}"
		].join('\n');

		document.head.appendChild(styleColors);
		styleColors.setAttribute('type', 'text/css');
		styleColors.append(styleColorsContent);
	});
}

export default setStyleColors;